
import { Component, Mixins } from 'vue-property-decorator';

import HeaderInfosMixin from '../header-infos';
import ConfigurationCollectionMixin from '@/mixins/data-operations/collection/configuration-collection-mixin';

import { tableToStorageConfs } from '@/store/modules/easy-firestore/table-to-storage-confs';
import { TABLE_TO_STORAGE_CONFIGURATIONS_ITEM } from '@/constants/router/routes-names';
import {
	ACTIVATED,
	CONF_CLIENT_VERSION,
	ENVIRONMENT,
	GCP_PROJECT,
	GCS_DEST_BUCKET,
	GCS_DEST_PREFIX,
	ID,
	ARCHIVED,
} from '@/constants/data-operations/listing/header-items';

@Component
export default class TableToStorageConfigurationsListingView extends Mixins(
	HeaderInfosMixin,
	ConfigurationCollectionMixin
) {
	moduleName: string = tableToStorageConfs.moduleName;
	overriddenColumns: string[] = ['id'];

	get routeName() {
		return TABLE_TO_STORAGE_CONFIGURATIONS_ITEM;
	}

	get headers() {
		return [ARCHIVED, ENVIRONMENT, CONF_CLIENT_VERSION, ID, GCP_PROJECT, GCS_DEST_BUCKET, GCS_DEST_PREFIX, ACTIVATED];
	}
}
